import { ReactNode } from 'react';
import { tw } from 'twind';
import AuthHeader, { AuthHeaderProps } from 'components/01_tw/authentication/AuthHeader';
import AuthLinks, { AuthLinksProps } from 'components/01_tw/authentication/AuthLinks';

interface AuthPanelProps {
    children: ReactNode;
    description?: AuthHeaderProps['description'];
    heading: AuthHeaderProps['heading'];
    links?: AuthLinksProps['links'];
    signupText?: AuthLinksProps['signupText'];
}

const AuthPanel = ({ children, description, heading, links, signupText }: AuthPanelProps) => (
    <div className={tw`min-h-full justify-center py-12 sm:px-6 lg:px-8`}>
        <AuthHeader heading={heading} description={description} />
        <div className={tw`mx-auto max-w-xl mt-8 w-full`}>
            <div className={tw`bg-white py-10 px-6 sm:(rounded-xl px-10)`}>
                {children}
                {links && <AuthLinks links={links} signupText={signupText} />}
            </div>
        </div>
    </div>
);

export default AuthPanel;
