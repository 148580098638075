import { tw } from 'twind';
import { assertUnreachable } from 'lib/assert-unreachable';
import Heading from 'components/01_tw/typography/Heading';
import Icon from 'components/01_tw/Icon';

interface AlertProps {
    gutterBottom?: boolean;
    text: string;
    title?: string;
    size?: 'base' | 'xsm';
    variant: 'success' | 'info' | 'warning' | 'error';
}

const Alert = ({ gutterBottom = false, size = 'base', text, title, variant }: AlertProps) => {
    let color;
    let icon;

    switch (variant) {
        case 'success':
            color = 'emerald';
            icon = 'FaCheckCircle';
            break;
        case 'info':
            color = 'blue';
            icon = 'FaInfoCircle';
            break;
        case 'warning':
            color = 'amber';
            icon = 'FaExclamationTriangle';
            break;
        case 'error':
            color = 'rose';
            icon = 'FaTimesCircle';
            break;
        default:
            assertUnreachable(variant);
    }

    return (
        <div
            className={tw(`flex rounded bg-${color}-50 text(${color}-500 left)`, {
                'p-4': size === 'base',
                'px-2.5 py-2': size === 'xsm',
                'mb-4': gutterBottom
            })}
        >
            <Icon
                name={icon}
                className={tw('mt-0.5', { 'mr-3': size === 'base', 'mr-2': size === 'xsm' })}
                size={size === 'base' ? 1.2 : 1}
            />
            <span>
                {title && (
                    <Heading as="h3" font="sans" className={tw(`font-medium mb-1 override:(text(${color}-700 base))`)}>
                        {title}
                    </Heading>
                )}
                <p className={tw`text-${color}-700 font-sans text-${size}`}>{text}</p>
            </span>
        </div>
    );
};

export default Alert;
