import React, { forwardRef, Ref, InputHTMLAttributes, useEffect, useRef } from 'react';
import mergeRefs from 'react-merge-refs';

/**
 * Base input component used to build other components
 * focusOnLoad: Allows auto-focus of input, especially useful in modals
 */
export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    focusOnLoad?: boolean;
    id?: string;
    innerRef?: Ref<HTMLInputElement>;
}

const Input = ({
    autoComplete,
    className,
    disabled,
    focusOnLoad,
    id,
    innerRef,
    name,
    onChange,
    placeholder,
    type = 'text',
    value,
    ...props
}: InputProps) => {
    const localRef = useRef(null);

    useEffect(() => {
        if (focusOnLoad) {
            if (!localRef.current) {
                return;
            }

            localRef.current.focus();
            localRef.current.select();
        }
    }, [focusOnLoad]);

    return (
        <input
            {...props}
            autoComplete={autoComplete}
            className={className}
            disabled={disabled}
            id={id}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            ref={mergeRefs([localRef, innerRef])}
            type={type}
            value={value}
        />
    );
};

export default forwardRef<HTMLInputElement, InputProps>((props, ref) => <Input {...props} innerRef={ref} />);
