import { SubmissionError } from 'redux-form';
import { login } from 'store/actions';
import withRedux from 'next-redux-wrapper';
import router from 'next/router';
import makeStore from 'make-store';
import Content from 'components/01_tw/Content';
import Head from 'components/Head';
import LoginForm from 'components/01_tw/authentication/LoginForm';
import Page from 'components/Page';

class LoginPage extends Page {
    constructor(props) {
        super(props);
        this.mustBeLoggedOut = true;
    }

    handleSubmit = ({ email, password }) => {
        const { dispatch } = this.props;

        return Promise.resolve()
            .then(() => {
                if (!email) {
                    throw new SubmissionError({
                        email: 'Missing.',
                        _error: 'Failed to log in.'
                    });
                }

                if (!password) {
                    throw new SubmissionError({
                        password: 'Missing.',
                        _error: 'Failed to log in.'
                    });
                }

                return dispatch(login({ email, password }));
            })
            .then(() => {
                router.push('/dashboard');
            })
            .catch((err) => {
                if (err instanceof SubmissionError) {
                    throw err;
                }

                throw new SubmissionError({ _error: err.message });
            });
    };

    render() {
        return (
            <Content bgColor="gray" padding={['top']}>
                <Head />
                <LoginForm onSubmit={this.handleSubmit} />
            </Content>
        );
    }
}

const mapStateToProps = function (state) {
    return {
        user: state.auth.user
    };
};

export default withRedux(makeStore, mapStateToProps)(LoginPage);
