import { ReactNode } from 'react';
import { tw } from 'twind';
import { View } from 'components/01_tw/View';

interface TextProps {
    as?: 'p' | 'span' | 'div';
    align?: 'left' | 'right' | 'center';
    children: ReactNode;
    className?: string;
    color?: 'black' | 'dark' | 'medium' | 'light' | 'lightest' | 'white' | 'blue' | 'emerald';
    font?: 'sans' | 'serif';
    gutterBottom?: boolean;
    size?: 'xs' | 'xsm' | 'sm' | 'base' | 'lg' | 'xl' | '2xl';
    tracking?: 'wider' | 'widest';
    transform?: 'uppercase' | 'lowercase' | 'capitalize';
    weight?: 'normal' | 'medium' | 'semibold' | 'bold';
    wordBreak?: 'words' | 'all' | 'normal';
    underline?: boolean;
}

const Text = ({
    align,
    as = 'p',
    children,
    className,
    color = 'black',
    font = 'sans',
    gutterBottom,
    size,
    tracking,
    transform,
    weight,
    wordBreak = 'words',
    underline
}: TextProps) => (
    <View
        as={as}
        className={tw(
            `break-${wordBreak}`,
            {
                'text-gray-900': color === 'black',
                'text-gray-700': color === 'dark',
                'text-gray-600': color === 'medium',
                'text-gray-500': color === 'light',
                'text-gray-400': color === 'lightest',
                'text-white': color === 'white',
                'text-blue-500': color === 'blue',
                'text-emerald-500': color === 'emerald'
            },
            {
                'mb-2': gutterBottom && size === 'xs',
                'mb-3': gutterBottom && size === 'sm',
                'mb-5': (gutterBottom && !size) || (gutterBottom && size === 'base'),
                'mb-8':
                    (gutterBottom && size === 'lg') ||
                    (gutterBottom && size === 'xl') ||
                    (gutterBottom && size === '2xl'),
                [`text-${size}`]: !!size,
                [`text-${align}`]: !!align,
                [`font-${font}`]: !!font,
                [`font-${weight}`]: !!weight,
                [`tracking-${tracking}`]: !!tracking,
                [`${transform}`]: !!transform,
                [`${underline}`]: !!underline
            },
            className
        )}
    >
        {children}
    </View>
);

export default Text;
