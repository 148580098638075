import Alert from 'components/01_tw/Alert';
import Button from 'components/01_tw/Button';
import TextField from 'components/01_tw/TextField';
import AuthPanel from 'components/01_tw/authentication/AuthPanel';
import { Field, reduxForm } from 'redux-form';
import { tw } from 'twind';

const LoginForm = ({ error, handleSubmit, submitting, submitSucceeded, entityType }) => (
    <AuthPanel
        heading={`Log in to Pastel ${entityType ? `${entityType}` : ''}`}
        description={`${entityType ? `This ${entityType} is restricted. Log in to access.` : ''}`}
        links={['forgot-password', 'signup', 'sso']}
    >
        <form onSubmit={handleSubmit}>
            {error && <Alert variant="error" text={error} gutterBottom />}
            {submitSucceeded && !submitting && <Alert variant="success" text="Successfully logged in!" gutterBottom />}
            <Field
                autoComplete="email"
                component={TextField}
                label="Email"
                name="email"
                placeholder="your.name@company.com"
                required
                type="email"
            />
            <Field
                component={TextField}
                label="Password"
                name="password"
                placeholder="••••••••••"
                required
                type="password"
            />
            <div className={tw`mt-8 text-center`}>
                <Button disabled={submitting} type="submit" variant="solid" colorScheme="blue">
                    Log in
                </Button>
            </div>
        </form>
    </AuthPanel>
);

export default reduxForm({
    form: 'loginForm'
})(LoginForm);
