import { tw } from 'twind';
import Link from 'next/link';

/**
 * Render links for Authentication forms
 * Pass the links you want to display as an array of strings
 */
type AuthLink = 'signup' | 'login' | 'sso' | 'forgot-password';
export interface AuthLinksProps {
    links: AuthLink[];
    signupText?: boolean;
}
interface AuthPageProps {
    name: AuthLink;
    href: string;
    text: string;
    signupText?: string;
}
const authPages: AuthPageProps[] = [
    {
        name: 'forgot-password',
        href: '/forgot-password',
        text: 'Forgot your password?'
    },
    {
        name: 'signup',
        href: '/signup',
        text: "Don't have an account?"
    },
    {
        name: 'login',
        href: '/login',
        text: 'Log in with email',
        signupText: 'Already have an account?'
    },
    {
        name: 'sso',
        href: '/sso',
        text: 'Log in with SSO',
        signupText: 'Sign up with SSO'
    }
];

const AuthLinks = ({ links, signupText }: AuthLinksProps) => (
    <div className={tw`mt-8 text-center`}>
        {authPages
            .filter((page) => links.includes(page.name))
            .map((page) => (
                <Link href={page.href} key={page.href}>
                    <a
                        className={tw`mt-4 block font(sans medium) text-blue-500 text-blue-600(hover:& focus:& active:&) underline(hover:& focus:& active:&)`}
                    >
                        {signupText ? page.signupText : page.text}
                    </a>
                </Link>
            ))}
    </div>
);

export default AuthLinks;
