import { tw } from 'twind';
import Heading from 'components/01_tw/typography/Heading';
import Text from 'components/01_tw/typography/Text';

export interface AuthHeaderProps {
    heading: string;
    description?: string;
}

const AuthHeader = ({ heading, description }: AuthHeaderProps) => (
    <div className={tw`mx-auto w-full max-w-xl px-4`}>
        <a href="/">
            <img
                alt="Pastel logo"
                className={tw`mx-auto h-14 w-auto`}
                src="/static/images/logos/pastel-icon.svg"
                translate="no"
            />
        </a>
        <Heading as="h1" className={tw`mt-6 override:text-4xl`} align="center" gutterBottom>
            {heading}
        </Heading>
        {description && (
            <Text color="medium" align="center" className={tw`mt-6`}>
                {description}
            </Text>
        )}
    </div>
);

export default AuthHeader;
