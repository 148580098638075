import { Label } from '@radix-ui/react-label';
import { tw } from 'twind';
import Icon from 'components/01_tw/Icon';
import Input, { InputProps } from 'components/01_tw/Input';

/**
 * Complete form control including a label, input and help text (description).
 * Replaces InputGroup in our current app. TODO: Delete this comment when InputGroup is replaced app-wide.
 *
 * @interface TextFieldProps
 * @extends {InputProps}
 */
interface TextFieldProps extends InputProps {
    description?: string;
    globalError?: string;
    input: InputProps;
    label?: string;
    meta /* Property from Redux Form */;
}

const TextField = ({
    autoComplete,
    className,
    description,
    disabled,
    focusOnLoad,
    globalError,
    input,
    label,
    meta: { touched, error } = {},
    placeholder,
    type
}: TextFieldProps) => {
    const displayErrorMessage = Boolean(touched && (error || globalError));

    return (
        <div className={tw`mb-4 relative`}>
            {label && <Label className={tw`block mb-1.5 text(gray-600 left) font(medium sans)`}>{label}</Label>}
            <Input
                {...input}
                autoComplete={autoComplete}
                className={tw(
                    className,
                    'w-full font-sans px-4 py-3 outline-none rounded border border-gray-300 hover:border-gray-400 border-blue-600(focus:& active:&) transition-colors ease-in-out duration-100',
                    {
                        'text-rose-700 placeholder-rose-300 border border-rose-500': displayErrorMessage
                    }
                )}
                disabled={disabled}
                focusOnLoad={focusOnLoad}
                placeholder={placeholder || placeholder === '' ? placeholder : label}
                type={type}
            />
            {displayErrorMessage && (
                <>
                    <Icon
                        className={tw`text-rose-500 pointer-events-none absolute top-11 right-3`}
                        name="FaExclamationCircle"
                    />
                    <p className={tw`mt-2 text(xsm rose-700) font-sans`}>{error || globalError}</p>
                </>
            )}
            {description && !displayErrorMessage && <p className={tw`mt-2 text(xsm gray-500)`}>{description}</p>}
        </div>
    );
};

export default TextField;
